import React, { useEffect, useState, lazy, Suspense } from "react"
import { useParams } from "react-router"
import { Container, Row, Col, Spinner } from 'react-bootstrap'

import { NotFound } from '../components/NotFound'
import { fetchSlug } from '../requests/Slug'

const ReactMarkdown = lazy(() => import('react-markdown'))

export function Cms() {
    let { slug } = useParams()

    const [page, setPage] = useState([])
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)


    useEffect(() => {
        fetchSlug(slug, setPage, setIsLoaded, setError);
    }, [slug])
    return (
        <Container id={"page" + slug}>
            <Row>
                <Col>
                    {error && (
                        <NotFound />
                    )}
                    {isLoaded ? (
                        <>
                            <h1>
                                {page.name}
                            </h1>
                            <Suspense fallback={<Spinner />}>
                                <ReactMarkdown>
                                    {page.text}
                                </ReactMarkdown>
                            </Suspense>
                        </>
                    ) :
                        <div className="text-center">
                            <Spinner />
                        </div>
                    }
                </Col>
            </Row>
        </Container>
    )
}