import React, { useEffect, useState, lazy } from "react"
import { useParams, Link } from "react-router"
import { useAuth } from '../components/Auth'
import { useCart } from '../components/Cart'
import { ImageCarousel } from '../components/Carousel'
import FlashMessage from '../components/FlashMessage'
import { Video } from '../components/Video'
import { getMainImage } from '../components/Util'
import { fetchProduct } from '../requests/Product'
import { faFilm, faBook, faCartShopping, faBox } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Container, Row, Col, Spinner, Button, Table } from 'react-bootstrap'

const ReactMarkdown = lazy(() => import('react-markdown'))

export function Product() {
    let auth = useAuth();
    let cart = useCart();
    let { id } = useParams()
    const [product, setProduct] = useState([])
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        fetchProduct(id, auth.token, setProduct, setError, null, null, setIsLoaded);
    }, [auth.token, id])
    return (
        <Container id="me">
            <Row>
                <Col>
                    {error && (
                        <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true}>
                            <Alert variant="danger">
                                <span>
                                    {error.message} {error.detail}
                                </span>
                            </Alert>
                        </FlashMessage>
                    )}
                    {isLoaded ? (
                        <>
                            <Row>
                                <Col xs={12} lg={8}>
                                    <h1 className="mb-4">{product.category && (<Link to={"/products/category/" + product.category.id}>{product.category.name}</Link>)} / {product.name}</h1>
                                </Col>
                                <Col xs={12} lg={4} className="text-end">
                                    {product.tags?.length > 0 ? product.tags?.map((tag, i) => {
                                        return <Link key={"taglink" + tag.id} to={"/products/tag/" + tag.id}>
                                            {i === 0 ? tag.name : ', ' + tag.name}
                                        </Link>
                                    }) : '\u00A0'}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} lg={6}>
                                    {product.images?.length > 1 ? (
                                        <ImageCarousel images={product.images} />
                                    ) : (
                                        <img src={getMainImage(product)} className="d-block w-100" alt={product.name} />
                                    )}
                                </Col>
                                <Col>
                                    <Row className="h-100">
                                        <Col xs={12}>
                                            <ReactMarkdown>
                                                {product.description}
                                            </ReactMarkdown>
                                        </Col><Col xs={12} className="mt-auto">
                                            {product.children?.length > 0 && product.setparent ? (
                                                <>
                                                    <h4><FontAwesomeIcon icon={faFilm} /> Produkte</h4>
                                                    <p>zu diesem Set gehören:</p>
                                                    <Table striped hover size="sm" className="mb-0">
                                                        <tbody>
                                                            {product.children.map((child, i) => (
                                                                <tr key={"childproduct" + child.id}>
                                                                    <td>
                                                                        <Link to={"/products/" + child.id}>
                                                                            {child.name}
                                                                        </Link>
                                                                    </td>
                                                                    {i === 0 && (
                                                                        auth.scopes.includes("nutzer") ? (
                                                                            <td rowSpan={product.children.length} className="vmiddle">
                                                                                <Button className="py-2 w-100 my-auto" disabled={cart.busy} onClick={(e) => { e.preventDefault(); cart.addSet(product.id) }}>
                                                                                    <FontAwesomeIcon icon={faCartShopping} className="me-2" /> Set hinzufügen
                                                                                </Button>
                                                                            </td>
                                                                        ) : (
                                                                            <Link to="/login" className="btn btn-primary py-2 w-100">
                                                                                <FontAwesomeIcon icon={faCartShopping} className="me-2" /> bitte einloggen
                                                                            </Link>
                                                                        )
                                                                    )}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </>
                                            ) : (
                                                <>
                                                    <h4><FontAwesomeIcon icon={faBox} /> Geräte</h4>
                                                    <Table striped hover size="sm" className="mb-0">
                                                        <tbody>
                                                            {Array.isArray(product.devices) && product.devices.map(device => (
                                                                <tr key={"devicecart" + device.id}>
                                                                    <td className="align-middle">
                                                                        {device.name}
                                                                    </td>
                                                                    <td className="align-middle">
                                                                        {device.serial}
                                                                    </td>
                                                                    <td className="align-middle">
                                                                        {device.location.plz} {device.location.ort}
                                                                    </td>
                                                                    <td className="text-end" style={{ minWidth: 135 + "px" }}>
                                                                        {auth.scopes.includes("nutzer") ? (
                                                                            cart.isInCart(device.id, false) ? (
                                                                                <Button variant="warning" disabled={cart.busy} className="py-2 w-100" onClick={(e) => { e.preventDefault(); cart.removeDevice(device.id) }}>
                                                                                    <FontAwesomeIcon icon={faCartShopping} className="me-2" /> entfernen
                                                                                </Button>
                                                                            ) : <Button className="py-2 w-100" disabled={cart.busy} onClick={(e) => { e.preventDefault(); cart.addDevice(device.id) }}>
                                                                                <FontAwesomeIcon icon={faCartShopping} className="me-2" /> hinzufügen
                                                                            </Button>
                                                                        ) : (
                                                                            <Link to="/login" className="btn btn-primary py-2 w-100">
                                                                                <FontAwesomeIcon icon={faCartShopping} className="me-2" /> bitte einloggen
                                                                            </Link>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col lg="6">
                                    <h4><FontAwesomeIcon icon={faFilm} /> Videos</h4>
                                    {Array.isArray(product.videos) && product.videos.map(video => (
                                        <Video video={video} key={"videoplayer" + video.id} />
                                    ))}
                                </Col>
                                <Col lg="6">
                                    <h4><FontAwesomeIcon icon={faBook} /> Handbücher</h4>
                                    <ul>
                                        {Array.isArray(product.manuals) && product.manuals.map(m => {
                                            let url = m.url;
                                            if (url === null) {
                                                url = process.env.REACT_APP_DOMAIN + "ul/manuals/" + m.id + '.' + m.name.split('.').pop();
                                            }
                                            return (
                                                <li key={"manual" + m.id}>
                                                    <a href={url} target="_blank" rel="noreferrer">{m.name}</a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </Col>
                            </Row>
                        </>
                    ) :
                        <div className="text-center">
                            <Spinner />
                        </div>
                    }
                </Col>
            </Row>
        </Container>
    )
}