import { Card } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'
import { Link } from "react-router"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { getMainImage} from './Util'

export function ProductCard({ product }) {

    return (
        <Link className="d-block h-100" to={"/products/"+product.id}>
            <Card className="h-100">
                <LazyLoadImage className="card-img-top" effect="opacity" src={getMainImage(product)} />
                <Card.Body>
                    <h3>{product.name}</h3>
                    <ReactMarkdown>
                        {product.teaser}
                    </ReactMarkdown>
                </Card.Body>
                <Card.Footer className="text-end">
                    {product.tags.length > 0 ? product.tags.map((tag,i)=> {
                        return i===0 ? tag.name : ', '+tag.name
                    }) : '\u00A0'}
                </Card.Footer>
            </Card>
        </Link>
    )
}