import React, { useState, useEffect } from "react"
import { useParams } from "react-router"
import { useAuth } from '../components/Auth'
import { Alert, Spinner, Container } from 'react-bootstrap'

export function ChangeEmail() {
    let auth = useAuth();
    const [submitting, setSubmitting] = useState(true)
    const [success, setSuccess] = useState(false)
    let { uuid, hash } = useParams()
    useEffect(() => {
        setSubmitting(true);
        fetch(process.env.REACT_APP_API + '/users/changemail/'+uuid+'/'+hash, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
        }).then(async res => {
            if (!res.ok) {
                const j = await res.json()
                throw new Error(j.detail)
            }
            return res.json()
        }).then(data => {
            setSubmitting(false);
            setTimeout(function () {
                auth.signout(function(){
                    return true
                })
            }, 2000)
            setSuccess(true);
        }).catch((e) => {
            setSubmitting(false)
        });
    }, [uuid, auth, hash])
    return (
        <Container className="d-flex flex-column justify-content-center align-items-center text-center">
            <h1 className="mt-5">Email-Änderung</h1>
            <div className="w-50 mt-5">
                {submitting ? (
                    <Spinner title="Validiere"/>
                ) : (
                    success ? <Alert variant="primary">
                        Ihre Email wurde erfolgreich geändert..
                    </Alert> :
                    <Alert variant="danger">
                        Ihre Email-Änderung konnte vorgenommen werden. Bitte überprüfen Sie Ihren Link.
                    </Alert>
                )}
            </div>
        </Container>
    )
}