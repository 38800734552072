import { memo } from 'react'
import { Link } from "react-router"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'

export const ObjectListingRow = memo(function ({props, item, hasActions, getFormattedVal, useuuid, objectname, itemTitle, deleteItem }) {
    return <tr className={props.rowClass ? props.rowClass(item) : ''}>
        {Array.isArray(props.attributes) && props.attributes.map(attribute => (
            <td className={typeof attribute.click === 'function' ? 'pointer' : ''} key={item.id + '_' + attribute.label} onClick={() => {
                if (typeof attribute.click === 'function') {
                    attribute.click(item)
                }
            }}>
                {getFormattedVal(item, attribute)}
            </td>
        ))}
        {hasActions &&
            <td className="text-end">
                {Array.isArray(props.actions) && props.actions.map((action, i) => (
                    ((typeof action.condition !== 'function' || action.condition(item))) && (
                        <button onClick={() => { action.click(useuuid ? item.uuid : item.id, item) }} type="button" title={action.title} className="btn btn-primary btn-xs mr-2" key={item.id + '_action_' + i}>
                            <FontAwesomeIcon className={typeof action.className === 'function' ? action.className(item.id) : action.className ? action.className : ''} icon={typeof action.icon === 'function' ? action.icon(item.id) : action.icon} />
                        </button>
                    )
                ))}
                {props.edit &&
                    <Link to={"/" + (props.admin ? "admin/" : "") + objectname + "/" + (useuuid ? item.uuid : item.id)} title={"'" + item[itemTitle] + "' bearbeiten"}>
                        <button type="button" className="btn btn-primary btn-xs ms-2">
                            <FontAwesomeIcon icon={faPen} />
                        </button>
                    </Link>
                }
                {props.delete &&
                    <button type="button" className="btn btn-primary btn-xs ms-2" onClick={() => { deleteItem(useuuid ? item.uuid : item.id, item[itemTitle]) }}>
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                }
            </td>
        }
    </tr>
})