import React, { useState } from "react"
import { useNavigate, useLocation } from "react-router"
import { useForm } from "react-hook-form"
import { useCookies } from "react-cookie"
import { Alert, Button, Spinner, FloatingLabel, Form } from 'react-bootstrap'
import { useAuth } from './Auth'
import { devlog } from './Util'
import FlashMessage from './FlashMessage'

export function LoginForm() {
    let navigate = useNavigate()
    let location = useLocation()
    let auth = useAuth()

    const { register, handleSubmit } = useForm()
    const [submitting, setSubmitting] = useState(false)
    const [cookies] = useCookies(['essential'])

    const onSubmit = data => {
        setSubmitting(true)
        let { from } = location.state || { from: { pathname: "/" } }

        auth.signin(data.email, data.password, (data, error) => {
            if (error || data === null) {
                setSubmitting(false)
                auth.setError(error)
                setTimeout(function () {
                    auth.setError(false)
                }, auth.errorDuration)
            } else {
                if ("Notification" in window) {
                    if (Notification.permission !== "denied" && Notification.permission !== "granted") {
                        Notification.requestPermission().then(function (permission) {
                            if (permission === "granted") {
                                return true
                            }
                        })
                    }
                } else {
                    devlog("This browser does not support desktop notification");
                }
                setSubmitting(false)
                navigate(from)
            }
        })
    }
    return (
        <form id="login" className="form-signin text-center w-100" onSubmit={handleSubmit(onSubmit)}>
            <FloatingLabel
                label="Email"
                className="mb-3"
            >
                <Form.Control {...register('email', { required: true })} type="email" placeholder="name@example.com" autoFocus required/>
            </FloatingLabel>
            <FloatingLabel
                label="Password"
                className="mb-3"
            >
                <Form.Control {...register('password', { required: true })} type="password" placeholder="Password" required/>
            </FloatingLabel>

            {auth.error && (
                <FlashMessage duration={auth.errorDuration} persistOnHover={true}>
                    <Alert variant="danger">
                        <span>
                            {auth.error}
                        </span>
                    </Alert>
                </FlashMessage>
            )}
            <div className="d-grid gap-2">
                <Button disabled={(submitting || !cookies['essential'])} variant="primary" type="submit">
                {submitting ? (
                        <Spinner animation="border" variant="light" size="sm"></Spinner>
                    ) : cookies['essential'] ? "Login" : 'Bitte aktivieren Sie essentielle Cookies'}
                </Button>
            </div>
        </form>
    )
}