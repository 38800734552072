import React, { useState, useEffect } from "react"
import { Alert } from 'react-bootstrap'
import { useNavigate, useParams } from "react-router"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faBoxesStacked } from '@fortawesome/free-solid-svg-icons'
import { ObjectListing } from '../../components/ObjectListing'
import { useAuth } from '../../components/Auth'
import FlashMessage from '../../components/FlashMessage'
import { fetchLocations } from '../../requests/Locations'
import { fetchProducts } from '../../requests/Products'

export function AdminDevices() {
    let auth = useAuth();
    let { product_id } = useParams()
    const navigate = useNavigate()
    const [locations, setLocations] = useState([])
    const [products, setProducts] = useState([])
    const [error, setError] = useState(null)
    var filters = []

    useEffect(() => {
        fetchLocations(auth.token, setLocations, setError);
        fetchProducts(auth.token, setProducts, setError, null)
    }, [auth.token])
    if (locations.length > 0) {
        filters.push({
            label: 'Standort',
            requestAttribute: 'location_id',
            toOption: (u) => {
                return u.name
            },
            options: locations
        })
    }
    if (products.length > 0) {
        filters.push({
            label: 'Produkt',
            requestAttribute: 'product_id',
            toOption: (u) => {
                return u.name
            },
            options: products
        })
    }
    var activefilters = []
    if (product_id) {
        activefilters.push(
            {
                key: "product_id",
                value: product_id
            }
        )
    }
    const attributes = [
        {
            key: "name",
            parent: "product",
            label: "Produkt",
            order: true,
            click: function (d, p) {
                navigate({ pathname: "/admin/products/" + d.product_id });
            }
        },
        {
            key: "serial",
            label: "Geräte- / Inventarnummer",
            order: true,
            format: (d,device) => {
                if(d.length > 0) {
                    return d
                } else if (device.inventar) {
                    return device.inventar
                }
                return '-'
            }
        },
        {
            key: "name",
            label: "Name",
            order: true,
        },
        {
            key: "name",
            parent: 'location',
            label: "Standort",
            order: true,
        },
        {
            key: "disabled",
            label: "Online",
            order: true,
            format: (d) => {
                return d ? <FontAwesomeIcon className="text-body-tertiary" icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />
            }
        },
    ];
    if (error) {
        return <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true}>
            <Alert variant="danger">
                <span>
                    {error.message} {error.detail}
                </span>
            </Alert>
        </FlashMessage>
    } else {
        return <ObjectListing activefilters={activefilters} objectname="devices" showTitle={true} admin={true} title="Geräte" icon={faBoxesStacked} attributes={attributes} defaultSort="product.name" defaultAsc="true" add={true} useuuid={false} edit={true} delete={true} filters={filters} search={true} headerButtons={[]} />
    }
}