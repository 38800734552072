import { React, useEffect, useState, useRef } from "react"
import { Alert, Col, Table, Form } from 'react-bootstrap'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import LoadingBar from 'react-top-loading-bar'
import FlashMessage from './FlashMessage'
import ReactPaginate from 'react-paginate'
import scrollToElement from 'scroll-to-element'
import { Link } from "react-router"
import { useAuth } from './Auth'
import { devlog } from './Util'
import { ObjectListingRow } from './ObjectListingRow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons'

export function ObjectListing(props) {
    const loaderRef = useRef(null)
    const [error, setError] = useState(null);
    const [search, setSearch] = useState('');
    const [activefilters, setActivefilters] = useState(props.activefilters || []);
    const [items, setItems] = useState([]);
    const [deleted, setDeleted] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [skip, setSkip] = useState(0);
    const [lastTotal, setLastTotal] = useState(0);
    const [orderBy, setOrderBy] = useState(props.defaultSort);
    const [asc, setAsc] = useState(props.defaultAsc !== 'false');
    const itemsPerPage = props.itemsPerPage ? props.itemsPerPage : 20;
    const objectname = props.objectname;
    var pagination = true
    if (typeof props.pagination !== "undefined") {
        pagination = props.pagination
    }
    let auth = useAuth();
    const hasActions = props.add || props.edit || props.delete || props.actions?.length > 0;
    const noCache = props.noCache || false
    const useuuid = props.useuuid ? props.useuuid : false;
    var itemTitle = useuuid ? 'uuid' : 'id'
    props.attributes.every((v) => {
        if ((v.key === 'name' || v.key === 'title') && typeof v.parent === "undefined") {
            itemTitle = v.key
            return false
        }
        return true
    })

    const onPageChange = (data) => {
        setSkip(data.selected * itemsPerPage);
        scrollToElement('#olheader', {
            offset: -80,
            duration: 200
        })
    }
    const toggleOrder = (key, parent) => {
        if (parent !== "undefined" && typeof parent !== "undefined" && parent !== null) {
            key = parent + '.' + key
        }
        setOrderBy(key);
        setAsc(!asc);
    }
    const getToggleClasss = (key, parent) => {
        var ret = 'btn-order-toggle'
        const parts = orderBy.split(".");
        if ((parts.length === 1 && key === parts[0] && typeof parent === "undefined") ||
            (parts.length === 2 && key === parts[1] && parent === parts[0])) {
            if (asc) {
                ret += ' asc';
            } else {
                ret += ' desc';
            }
        }
        return ret;
    }
    const getVal = (item, attribute) => {
        if (Array.isArray(attribute.key)) {
            var val = [];
            attribute.key.forEach(key => {
                if (typeof attribute.parent !== "undefined" && typeof item[attribute.parent] !== "undefined" && item[attribute.parent] !== null) {
                    val.push(item[attribute.parent][key]);
                } else {
                    val.push(item[key]);
                }
            });
            return val.join(' ');
        }
        if (typeof attribute.parent !== "undefined" && typeof item[attribute.parent] !== "undefined" && item[attribute.parent] !== null) {
            return item[attribute.parent][attribute.key];
        } else {
            return item[attribute.key];
        }
    }

    const getFormattedVal = (item, attribute, parent_row = null) => {
        if (typeof attribute.format !== "undefined") {
            return attribute.format(getVal(item, attribute), item, parent_row);
        }
        return getVal(item, attribute);
    }
    const getActiveFilterVal = (name) => {
        for (const [, v] of Object.entries(activefilters)) {
            if (v.key === name) {
                return v.value
            }
        }
        return ''
    }
    const deleteItem = function (item, title) {
        if (window.confirm("'" + title + "' wirklich löschen?")) {
            fetch(process.env.REACT_APP_API + '/' + objectname + '/' + item, {
                method: 'DELETE',
                cache: 'no-cache',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + auth.token
                },
            })
                .then(
                    async res => {
                        if (!res.ok) {
                            setError({ message: await res.text() });
                            setDeleted(false);
                            setTimeout(() => {
                                setError(null)
                            }, 5000)
                        } else {
                            setDeleted(true);
                            setRefreshKey(oldKey => oldKey + 1)
                        }
                    },
                    (error) => {
                        setError(error);
                    }
                )
        }
    }
    useEffect(() => {
        const abortController = new AbortController();
        document.title = props.title
        var uri = '';
        if (lastTotal > Date.now() - 60000) {
            loaderRef.current.continuousStart()
            var action = objectname
            if (props.action?.length > 0) {
                action += '/' + props.action
            }
            uri = process.env.REACT_APP_API + '/' + action + '/?order_by=' + orderBy + '&asc=' + asc + '&skip=' + skip + '&limit=' + itemsPerPage;
            if (props.search && search.length > 0) {
                uri += '&fltr=' + encodeURIComponent(search)
            }
            if (activefilters.length > 0) {
                activefilters.forEach((af) => {
                    uri += '&' + encodeURIComponent(af.key) + '=' + encodeURIComponent(af.value)
                })
            }
            if (props.additionalListParams?.length > 0) {
                props.additionalListParams.forEach((p) => {
                    uri += '&' + p.attribute + "=" + p.value
                })
            }
            var headers = {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            }
            if (noCache) {
                headers['Cache-Control'] = 'no-cache';
                headers['Pragma'] = 'no-cache';
            }
            fetch(uri, {
                signal: abortController.signal,
                method: 'GET',
                cache: noCache ? 'no-store' : 'no-cache',
                headers: headers,
            }).then(res => res.json())
                .then(
                    (result) => {
                        setItems(result)
                        loaderRef?.current?.complete()
                    },
                    (error) => {
                        loaderRef?.current?.complete()
                        if (abortController.signal.aborted) {
                            devlog('The user aborted the request');
                        } else {
                            setError(error)
                        }
                    }
                )
        } else {
            uri = process.env.REACT_APP_API + '/' + objectname + '/total?';
            if (props.search && search.length > 0) {
                uri += 'fltr=' + encodeURIComponent(search);
            }
            if (activefilters.length > 0) {
                activefilters.forEach((af) => {
                    uri += '&' + encodeURIComponent(af.key) + '=' + encodeURIComponent(af.value)
                })
            }
            var cheaders = {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            }
            if (noCache) {
                cheaders['Cache-Control'] = 'no-cache';
                cheaders['Pragma'] = 'no-cache';
            }
            fetch(uri, {
                signal: abortController.signal,
                cache: noCache ? 'no-store' : 'no-cache',
                method: 'GET',
                headers: cheaders,
            }).then(res => res.json())
                .then(
                    (result) => {
                        setPageCount(Math.ceil(result / itemsPerPage));
                        setLastTotal(Date.now());
                    },
                    (error) => {
                        if (abortController.signal.aborted) {
                            devlog('The user aborted the request');
                        } else {
                            setError(error)
                        }
                    }
                )
        }
        const interval = setInterval(() => {
            setLastTotal(0);
        }, 60000)
        return () => {
            clearInterval(interval)
            abortController.abort();
        }
    }, [auth, noCache, skip, lastTotal, objectname, refreshKey, asc, orderBy, props.refresh, props.title, props.search, props.action, search, activefilters, props.additionalListParams, itemsPerPage])
    return (
        <div className={props.objectname + " container"}>
            <LoadingBar
                className="loader"
                ref={loaderRef}
            />
            {error && (
                <Alert variant="danger">
                    <p>{error.message}</p>
                </Alert>
            )}
            <div className="row mb-4">
                <Col xs={props.headersize ? props.headersize : 3}>
                    {props.showTitle && (
                        <h2 id="olheader"><FontAwesomeIcon icon={props.icon} /> {props.title}</h2>
                    )}
                </Col>
                <Col className="inlines text-end">
                    {props.headerButtons}
                    {props.search && Array.isArray(props.filters) && props.filters.map((filter, i) => (
                        filter.boolean ? (
                            <Form.Group key={"filter" + i} className="d-inline ms-2 text-end" controlId="formBasicCheckbox">
                                <Form.Check className="me-0" onChange={(e) => {
                                    setLastTotal(0);
                                    setActivefilters([
                                        {
                                            key: filter.requestAttribute,
                                            value: e.target.checked
                                        }
                                    ])
                                }} inline value={true} defaultChecked={filter.default} type="switch" label={filter.label} />
                            </Form.Group>
                        ) : (
                            <div className="d-inline mx-2" key={"filter" + i}>
                                <label className="me-2">
                                    {filter.label}
                                </label>
                                <select className="form-control mw-10" onChange={(e) => {
                                    setLastTotal(0)
                                    setActivefilters([
                                        {
                                            key: filter.requestAttribute,
                                            value: e.target.value
                                        }
                                    ])
                                }} defaultValue={getActiveFilterVal(filter.requestAttribute)}>
                                    <option value="0">alle</option>
                                    {filter.options.map((option, j) => (
                                        <option key={"filter" + i + 'option' + j} value={option.id}>
                                            {filter.key ? (
                                                filter.toOption(option[filter.key])
                                            ) : filter.toOption(option)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )
                    )
                    )}
                    {props.search && (
                        <input className="form-control" type="text" name="search" placeholder="Suche" onChange={(e) => { setLastTotal(0); setSearch(e.target.value); }} />
                    )}
                </Col>
            </div>
            {deleted && (
                <FlashMessage duration={2000}>
                    <Alert variant="success">
                        <p>{props.title} erfolgreich gelöscht</p>
                    </Alert>
                </FlashMessage>
            )}
            <div className="table-responsive refreshing-table">
                <Table size="sm" striped bordered hover>
                    <thead>
                        <tr>
                            {Array.isArray(props.attributes) && props.attributes.map(attribute => (
                                <th key={attribute.label}>
                                    {attribute.order ? (
                                        <button className={getToggleClasss(attribute.key, attribute.parent)} onClick={() => { toggleOrder(attribute.key, attribute.parent) }}>
                                            {attribute.label}
                                        </button>
                                    ) : attribute.label}
                                </th>
                            ))}
                            {hasActions &&
                                <th className="text-end">
                                    {props.add &&
                                        <Link to={"/" + (props.admin ? 'admin/' : '') + objectname + "/create"} title={"Create new " + props.title}>
                                            <button type="button" className="btn btn-primary btn-xs">
                                                <FontAwesomeIcon icon={faPlus} />
                                            </button>
                                        </Link>
                                    }
                                </th>
                            }
                        </tr>
                    </thead>
                    <tbody>

                        {Array.isArray(items) && items.map((item, n) => (
                            <LazyLoadComponent placeholder={<tr></tr>} key={"olrow" + n}>
                                <ObjectListingRow props={props} item={item} hasActions={hasActions} getFormattedVal={getFormattedVal} useuuid={useuuid} objectname={objectname} itemTitle={itemTitle} deleteItem={deleteItem} />
                                {props.subrows?.length > 0 && props.subrows[0].hidden(item.id) !== true && Array.isArray(item[props.subrows[0].model]) && item[props.subrows[0].model].map((subrow, i) => (
                                    <tr className={props.subrows[0].rowClass ? props.subrows[0].rowClass(subrow) : ''} key={item.id + '_subrow_' + i}>
                                        {Array.isArray(props.subrows) && props.subrows.map(subcol => (
                                            <td className={subcol.class} colSpan={subcol.colspan > 1 ? subcol.colspan : 1} key={item.id + '_subrow_' + i + '_' + subcol.label}>
                                                {getFormattedVal(subrow, subcol, item)}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </LazyLoadComponent>
                        ))}
                    </tbody>
                </Table>
            </div>
            {pagination && pageCount > 1 && (
                <nav>
                    <ReactPaginate
                        previousLabel={<FontAwesomeIcon icon={faCaretLeft} />}
                        nextLabel={<FontAwesomeIcon icon={faCaretRight} />}
                        breakLabel={'...'}
                        breakClassName={'break-me page-link'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={skip / itemsPerPage}
                        onPageChange={onPageChange}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        subContainerClassName={'pages pagination'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </nav>
            )}
        </div>
    );
}

