import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router"
import { Alert, Spinner, Container, FloatingLabel, Form, Button, Row, Col } from 'react-bootstrap'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from '../../components/Auth'
import FlashMessage from '../../components/FlashMessage'

export function LocationCreate() {
    let auth = useAuth();
    const navigate = useNavigate()
    const { register, handleSubmit } = useForm()
    const [submitting, setSubmitting] = useState(false)
    const [location, setLocation] = useState([])
    const [saved, setSaved] = useState(null)
    const [error, setError] = useState(null)
    const [errorid, setErrorId] = useState(0)

    const onSubmit = data => {
        setSubmitting(true)
        fetch(process.env.REACT_APP_API + '/locations/', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(async res => {
            if (!res.ok) {
                const j = await res.json()
                throw new Error(j.detail)
            }
            return res.json()
        }).then(t => {
            setSubmitting(false)
            setSaved(true)
            setLocation(t)
            setTimeout(function () {
                navigate({ pathname: "/admin/locations/" })
            }, 1000)
        }).catch((e) => {
            setError({ message: e.name, detail: e.message })
            setErrorId(errorid + 1)
            setSubmitting(false)
        })
    }

    return (
        <Container className="d-flex flex-column">
            <h1 className="my-5"><FontAwesomeIcon icon={faBuilding} /> Standort anlegen</h1>
            <Row className="w-100">
                <Col>
                    <form className="form-signin text-center w-100" onSubmit={handleSubmit(onSubmit)}>
                        <FloatingLabel
                            label="Name *"
                            className="mb-3"
                        >
                            <Form.Control {...register('name', { required: true })} defaultValue={location.name} type="text" placeholder="Name" required />
                        </FloatingLabel>
                        <FloatingLabel
                            label="Straße *"
                            className="mb-3"
                        >
                            <Form.Control {...register('strasse', { required: true })} defaultValue={location.strasse} type="text" placeholder="Straße" required />
                        </FloatingLabel>
                        <FloatingLabel
                            label="PLZ *"
                            className="mb-3"
                        >
                            <Form.Control {...register('plz', { required: true })} defaultValue={location.plz} type="number" placeholder="PLZ" required />
                        </FloatingLabel>
                        <FloatingLabel
                            label="Ort *"
                            className="mb-3"
                        >
                            <Form.Control {...register('ort', { required: true })} defaultValue={location.ort} type="text" placeholder="Ort" required />
                        </FloatingLabel>
                        <p className="text-end">
                            <small>* Pflichtfelder</small>
                        </p>
                        {error && (
                            <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true} key={errorid}>
                                <Alert variant="danger">
                                    <span>
                                        {error.message} {error.detail}
                                    </span>
                                </Alert>
                            </FlashMessage>
                        )}
                        {saved && (
                            <FlashMessage duration={2000}>
                                <Alert variant="success">
                                    <span>
                                        Standort erfolgreich angelegt
                                    </span>
                                </Alert>
                            </FlashMessage>
                        )}
                        <div className="d-grid gap-2">
                            <Button disabled={submitting} variant="primary" type="submit">
                                {submitting ? (
                                    <Spinner animation="border" variant="light" size="sm"></Spinner>
                                ) : "Anlegen"}
                            </Button>
                        </div>
                    </form>
                </Col>
            </Row>
        </Container>
    )
}