import packageJson from '../../package.json'
import React, { useState } from "react"
import { Link } from "react-router"
import { Disclaimer } from './Disclaimer'

export function Version() {
    const [showDisclaimer, setShowDisclaimer] = useState(false)
    return (
        <span className="copy">
            <Link to="/impressum">Impressum</Link> | <Link to="/datenschutz">Datenschutz</Link> | <button className="link" onClick={() => setShowDisclaimer(true)}>Cookie-Einstellungen</button> &copy; {process.env.REACT_APP_COMPANY} '23-'{new Date().getFullYear() - 2000} - V. {packageJson.version}
            {parseInt(process.env.REACT_APP_DEV) === 1 && (
                <>
                    <span className="ms-2 pulse_alert_danger">
                        <b>DEV</b>
                    </span>
                </>
            )}
            <Disclaimer showDisclaimer={showDisclaimer} setShowDisclaimer={setShowDisclaimer} />
        </span>
    );
}