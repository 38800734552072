import React, { useState, useEffect, lazy, Suspense } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router"
import { Alert, Spinner, Container, FloatingLabel, Form, Button, Row, Col } from 'react-bootstrap'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from '../../components/Auth'
import FlashMessage from '../../components/FlashMessage'

const MDEditor = lazy(() => import('@uiw/react-md-editor'))

export function Page() {
    let auth = useAuth()
    let { id } = useParams()
    const navigate = useNavigate()
    const { register, handleSubmit } = useForm()
    const [submitting, setSubmitting] = useState(false)
    const [text, setText] = useState("")
    const [page, setPage] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [saved, setSaved] = useState(null)
    const [error, setError] = useState(null)
    const [errorid, setErrorId] = useState(0)

    const onSubmit = data => {
        data.text = text
        setSubmitting(true)
        fetch(process.env.REACT_APP_API + '/pages/' + id, {
            method: 'POST',
            cache: 'no-store',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(async res => {
            if (!res.ok) {
                const j = await res.json()
                throw new Error(j.detail)
            }
            return res.json()
        }).then(t => {
            setSubmitting(false)
            setSaved(true)
            setPage(t)
            setTimeout(function () {
                navigate({ pathname: "/admin/pages/" })
            }, 1000)
        }).catch((e) => {
            setError({ message: e.name, detail: e.message })
            setErrorId(errorid + 1)
            setSubmitting(false)
        })
    }
    const fetchSlug = function (token, id) {
        fetch(process.env.REACT_APP_API + '/pages/' + id, {
            method: 'GET',
            cache: 'no-store',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Cache-Control": "no-cache",
                "Pragma": "no-cache",
                "Authorization": "Bearer " + token
            },
        })
            .then(res => res.json())
            .then(
                (res) => {
                    if (res.detail) {
                        setError({ message: res.detail })
                    } else {
                        setPage(res)
                        setText(res.text)
                    }
                    setIsLoaded(true)
                },
                (error) => {
                    setIsLoaded(true)
                    setError(error)
                }
            )
    }
    useEffect(() => {
        fetchSlug(auth.token, id);
    }, [id, auth.token])
    return (
        <Container className="d-flex flex-column">
            <h1 className="my-5"><FontAwesomeIcon icon={faLink} /> Seite bearbeiten</h1>
            <Row className="w-100">
                <Col>
                    {isLoaded ? (
                        <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col>
                                    <FloatingLabel
                                        label="Titel *"
                                        className="mb-3"
                                    >
                                        <Form.Control {...register('name', { required: true })} defaultValue={page.name} type="text" placeholder="Titel" required />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel
                                        label="Slug *"
                                        className="mb-3"
                                    >
                                        <Form.Control {...register('slug', { required: true })} defaultValue={page.slug} type="text" placeholder="Slug" required />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="formBasicCheckbox">
                                        <Form.Check {...register('disabled', {})} inline value={true} defaultChecked={page.disabled} type="switch" label={<p className="w-100">versteckt</p>} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label >Text</Form.Label>
                                    <Suspense fallback={<Spinner />}>
                                        <MDEditor
                                            value={text}
                                            onChange={setText}
                                        />
                                    </Suspense>
                                </Col>
                            </Row>
                            <p className="text-end">
                                <small>* Pflichtfelder</small>
                            </p>
                            {error && (
                                <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true} key={errorid}>
                                    <Alert variant="danger">
                                        <span>
                                            {error.message} {error.detail}
                                        </span>
                                    </Alert>
                                </FlashMessage>
                            )}
                            {saved && (
                                <FlashMessage duration={2000}>
                                    <Alert variant="success">
                                        <span>
                                            Seite erfolgreich gespeichert
                                        </span>
                                    </Alert>
                                </FlashMessage>
                            )}
                            <div className="d-grid gap-2">
                                <Button disabled={submitting} variant="primary" type="submit">
                                    {submitting ? (
                                        <Spinner animation="border" variant="light" size="sm"></Spinner>
                                    ) : "Speichern"}
                                </Button>
                            </div>
                        </form>
                    ) : <div className="text-center">
                        <Spinner />
                    </div>}
                </Col>
            </Row>
        </Container>
    )
}