import React, { useState, useEffect, useRef, useCallback } from "react"
import { Accordion, Alert, Button, Container, Row, Col, Table } from 'react-bootstrap'
import { useParams } from "react-router"
import LoadingBar from 'react-top-loading-bar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../../components/Auth'
import { useCart } from '../../components/Cart'
import { PlanDeviceRow } from '../../components/PlanDeviceRow'
import FlashMessage from '../../components/FlashMessage'
import { fetchCategories } from '../../requests/Categories'
import AccordionBody from "react-bootstrap/esm/AccordionBody"

export function Plan() {
    const loaderRef = useRef(null)
    let cart = useCart()
    let auth = useAuth()
    let { startdate } = useParams()
    const [categories, setCategories] = useState([])
    const [date, setDate] = useState(startdate ? new Date(startdate) : new Date())
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [devices, setDevices] = useState(null)
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
    const today = new Date()
    const prevMonth = () => {
        var newDate = date.setMonth(date.getMonth() - 1)
        setDate(new Date(newDate))
    }
    const nextMonth = () => {
        var newDate = date.setMonth(date.getMonth() + 1)
        setDate(new Date(newDate))
    }
    const fetchPlan = useCallback(() => {
        setLoading(true)
        fetch(process.env.REACT_APP_API + '/devices/plan/' + date.getFullYear() + "/" + (date.getMonth() + 1), {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        })
            .then(res => res.json())
            .then(
                (r) => {
                    if (r.detail) {
                        setError({ message: r.detail })
                    } else {
                        setDevices(r)
                    }
                    loaderRef.current.complete()
                    setLoading(false)
                },
                (error) => {
                    loaderRef.current.complete()
                    setLoading(false)
                    setError(error)
                }
            )
    }, [auth.token, date])
    useEffect(() => {
        loaderRef.current.continuousStart()
        fetchCategories(setCategories, setError, function () {
            fetchPlan()
        })
    }, [fetchPlan])
    return (
        <div className={(cart.busy ? 'busy ' : '') + (loading ? 'loading ' : '') + "d-flex flex-column px-3"}>
            <LoadingBar
                className="loader"
                ref={loaderRef}
            />
            {error && (
                <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true}>
                    <Alert variant="danger">
                        <span>
                            {error.message} {error.detail}
                        </span>
                    </Alert>
                </FlashMessage>
            )}
            <Container>
                <Row className="mb-5">
                    <Col>
                        <Button onClick={() => prevMonth()}><FontAwesomeIcon icon={faCaretLeft}/> zurück</Button>
                    </Col>
                    <Col className="text-center">
                        <h2>{date.toLocaleString("de-DE", { month: "long", year: "numeric" })}</h2>
                    </Col>
                    <Col className="text-end">
                        <Button onClick={() => nextMonth()}>weiter <FontAwesomeIcon icon={faCaretRight} /></Button>
                    </Col>
                </Row>
            </Container>
            {categories.map((category) => {
                var categoryDevices = []
                if (Array.isArray(devices)) {
                    categoryDevices = devices.filter((d) => { return d.product.category_id === category.id })
                    categoryDevices.sort((a, b) => {
                        return a.product.name === b.product.name ?
                            a.serial.localeCompare(b.serial)
                            : a.product.name.localeCompare(b.product.name)
                    })
                }
                return (
                    <Accordion key={"categoryaccordion" + category.id} defaultActiveKey="0" flush>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>{category.name}</Accordion.Header>
                            <AccordionBody className="p-0">
                                <Table className="text-small plan m-0" striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th className="name">Name / Seriennummer</th>
                                            {[...Array(lastDay).keys()].map((d) => {
                                                const isToday = date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && d === today.getDate()
                                                return (
                                                    <th className={isToday ? 'today' : ''}key={"catheader" + category.id + "-" + d}>
                                                        {(d + 1)}.
                                                    </th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {categoryDevices.length > 0 ? categoryDevices.map((d) => {
                                            return <PlanDeviceRow key={"plandevicerow" + d.id} device={d} month={date.getMonth()} year={date.getFullYear()} lastDay={lastDay} />
                                        }) : <tr>
                                            <td>keine Geräte vorhanden</td>
                                            {[...Array(lastDay).keys()].map((d) => {
                                                return (
                                                    <td key={"emptycat" + category.id + "-" + d}></td>
                                                )
                                            })}
                                        </tr>}
                                    </tbody>
                                </Table>
                            </AccordionBody>
                        </Accordion.Item>
                    </Accordion>
                )
            })}
        </div>
    )
}