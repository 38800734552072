import React from 'react'
import { createRoot } from 'react-dom/client'
import { CookiesProvider } from 'react-cookie'
import App from './App'
console.log(`%c

███████ ██   ██  █████  ██████  ███████      █████  ██      ██          ████████ ██   ██ ███████     ████████  ██████   ██████  ██      ███████ ██
██      ██   ██ ██   ██ ██   ██ ██          ██   ██ ██      ██             ██    ██   ██ ██             ██    ██    ██ ██    ██ ██      ██      ██
███████ ███████ ███████ ██████  █████       ███████ ██      ██             ██    ███████ █████          ██    ██    ██ ██    ██ ██      ███████ ██
     ██ ██   ██ ██   ██ ██   ██ ██          ██   ██ ██      ██             ██    ██   ██ ██             ██    ██    ██ ██    ██ ██           ██
███████ ██   ██ ██   ██ ██   ██ ███████     ██   ██ ███████ ███████        ██    ██   ██ ███████        ██     ██████   ██████  ███████ ███████ ██

`, "color: #f04124")
const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <App />
    </CookiesProvider>
  </React.StrictMode>
)